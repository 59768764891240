














































































import Vue from 'vue';
import { GroupDTO } from '@/data/dto/group.dto';
import BaseInputGroup from '@/components/BaseInputGroup.vue';
import SearchBar from '@/components/SearchBar.vue';

export default Vue.extend({
  name: 'EditUsersModal',
  components: {
    BaseInputGroup,
    SearchBar,
  },
  props: {
    userEmail: {
      type: String,
    },
    userName: {
      type: String,
    },
  },
  async beforeMount() {
    await this.$store.dispatch('getOneUser', { email: this.userEmail })
      .then((res) => {
        this.userGroups = res;
        return res;
      }).then((userGroups) => {
        this.$store.dispatch('getAllGroups').then((res) => {
          this.allGroups = res.filter((group: GroupDTO) => userGroups
            .filter((userGroup: GroupDTO) => group.uuid === userGroup.UUID).length === 0);
        });
      }).catch(() => {
        this.isOnError = true;
      });
  },
  data() {
    return {
      email: '',
      name: '',
      selectedGroups: [],
      userGroups: [],
      allGroups: [],
      search: '',
      isOnError: false,
    };
  },
  computed: {
    isInputsValid() {
      return this.$data.email !== '' && this.$data.selectedGroups.length !== 0;
    },
  },
  methods: {
    notifySuccess() {
      this.$notification.notify({
        message: 'Usuário alterado com sucesso',
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'success',
        timeout: 2000,
      });
    },

    notifyError() {
      this.$notification.notify({
        message: 'Não foi possivel executar a operação. Por favor tente novamente.',
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'danger',
        timeout: 3000,
      });
    },

    async removeUserFromGroup(uuid: string) {
      try {
        await this.$store.dispatch('removeUserFromApplicationGroup',
          { email: this.email, groupUUID: uuid });
        await this.reloadUserTable();
        this.notifySuccess();
      } catch (e) {
        this.notifyError();
      }
    },
    async submit() {
      try {
        await this.$store.dispatch('addUserToApplicationGroup',
          { email: this.email, groups: this.selectedGroups });

        this.updateFilterQuery('');
        await this.reloadUserTable();
        this.notifySuccess();
      } catch (e) {
        this.notifyError();
      }
    },

    async reloadUserTable() {
      await this.$store.dispatch('getOneUser', { email: this.userEmail })
        .then((res) => {
          this.userGroups = res;
        });
    },
    updateFilterQuery(search = '') {
      this.search = search;
    },

    groupsFilteredBySearch() {
      return this.$searchUtils.searchAll<GroupDTO>(this.search, this.allGroups,
        (group) => group.name);
    },
  },
});
