export default [
  {
    key: 'MANAGER_PERMISSION',
    value: 'true',
    label: 'AUTORIZAÇÃO',
  },
  {
    key: 'DASHBOARD_PERMISSION',
    value: 'true',
    label: 'DASHBOARD',
  },
  {
    key: 'IMAGE_UPLOAD',
    value: 'true',
    label: 'CAD. IMAGENS',
  },
  {
    key: 'KANTAR_REPROCESSING_PERMISSION',
    value: 'true',
    label: 'KANTAR REPROCESSAMENTO',
  },
  {
    key: 'REALTIME_MARKET_PERMISSION',
    value: 'true',
    label: 'GESTÃO DE PRAÇAS',
  },
  {
    key: 'REALTIME_SCHEDULE_PERMISSION',
    value: 'true',
    label: 'GRADE DO AFINACAO',
  },

];
