































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import GroupsPanel from '@/views/AuthorizationPanel/panels/Groups/Groups.vue';
import UsersPanel from '@/views/AuthorizationPanel/panels/Users/Users.vue';
import SearchBar from '@/components/SearchBar.vue';
import CreateGroup from '@/views/AuthorizationPanel/CreateGroup.vue';
import CreateUser from '@/views/AuthorizationPanel/CreateUser.vue';
import AddButton from '@/components/AddButton.vue';

@Component({
  components: {
    GroupsPanel,
    UsersPanel,
    SearchBar,
    AddButton,
  },
})
export default class ControlPanel extends Vue {
  $refs!: {
    tab: UsersPanel | GroupsPanel;
  }

  activeTabIndex = 0;

  currentTab?: string;

  tabOptions = [
    {
      component: 'GroupsPanel',
      icon: 'security',
      addButtonLabel: 'Novo Grupo',
    },
    {
      component: 'UsersPanel',
      icon: 'person',
      addButtonLabel: 'Novo Usuário',
    },
  ];

  get buttonLabel() {
    return this.tabOptions[this.activeTabIndex].addButtonLabel;
  }

  beforeMount() {
    const [initialPanel] = this.tabOptions;
    this.currentTab = initialPanel.component;
  }

  showCreateGroup() {
    this.$modal.show(
      CreateGroup,
      { },
      { adaptive: true, height: 'auto' },
      {
        'before-close': this.beforeModalClose,
      },
    );
  }

  beforeModalClose() {
    this.$refs.tab.reloadTable();
  }

  showCreateUser() {
    this.$modal.show(
      CreateUser,
      { },
      { adaptive: true, height: 'auto' },
      {
        'before-close': this.beforeModalClose,
      },
    );
  }

  // eslint-disable-next-line class-methods-use-this
  updateFilterQuery(search = '') {
    this.$refs.tab.updateFilterQuery(search);
  }

  handleAddButton() {
    switch (this.currentTab) {
      case 'UsersPanel':
        return this.showCreateUser();
      default:
        return this.showCreateGroup();
    }
  }

  selectTab(index: number) {
    this.activeTabIndex = index;
    this.currentTab = this.tabOptions[index].component;
  }
}
