<template>
    <div style="background-color: #333333; padding: 20px">
      <h2 style="font-weight: 100;">
        Editar Grupo
      </h2>

      <div style="display: flex; flex-direction: row; align-items: center">
        <p style="font-size: 18px;
        margin-right: 5px;color: rgb(115, 113, 113);
        padding-bottom: 6px;
        margin-top: -7px;
        border-bottom: 3px solid rgba(227, 227, 227, 0.08);">PA_</p>
        <base-input-group v-model="name" :place-holder="getGroupNameNormalized" label="Nome"/>
      </div>

      <div class="scroll">
        <div style="text-align: left;margin-top: 10px">
          <h4 style="font-weight: 100;border-bottom: 1px solid rgba(227, 227, 227, 0.08)">
            Permissões
          </h4>

          <div class="body">
            <div class="item" v-for="item in permissionsOptions" :key="item.key">
              <div class="squared-check-box">
                <input type="checkbox"
                       :id="item.key"
                       :value="item.key"
                       v-model="selectedPermissions">
                <label :for="item.key">{{ item.label }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button"
              style="width: 100%;margin-top: 1%;"
              class="btn btn-success btn-simple"
              @click="submit"
              :class="{ active : true}">
        SALVAR
      </button>
    </div>
</template>

<script>
import Vue from 'vue';
import BaseInputGroup from '@/components/BaseInputGroup.vue';
import permissions from '@/core/permissions';

export default Vue.extend({
  name: 'EditGroupModal',
  components: {
    BaseInputGroup,
  },
  computed: {
    getGroupNameNormalized() {
      return this.groupName.replace(/^PA_/, '');
    },
  },
  props: {
    uuid: {
      type: String,
    },
    groupName: {
      type: String,
    },
  },
  async beforeMount() {
    await this.$store.dispatch('getOneGroup', { uuid: this.uuid }).then((res) => {
      this.selectedPermissions = this.getEnabledFeatures(res.policies);
      this.isLoading = false;
    }).catch(() => {
      this.isOnError = true;
    });
  },
  data() {
    return {
      name: '',
      selectedPermissions: [],
      permissionsOptions: permissions,
    };
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('updateGroup',
          {
            uuid: this.uuid,
            name: this.name,
            permissions: this.selectedPermissions
              .map((key) => this.permissionsOptions
                .filter((permission) => permission.key === key)[0]),
          });

        this.$notification.notify({
          message: 'Grupo alterado com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 2000,
        });

        this.$emit('close');
      } catch (e) {
        this.$notification.notify({
          message: 'Não foi possivel executar a operação. Por favor tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger',
          timeout: 3000,
        });
      }
    },
    getEnabledFeatures(policies) {
      return Object.keys(policies).filter((key) => policies[key] === 'true');
    },
  },
});
</script>

<style lang="scss" scoped>
.body{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .item{
    flex: 1; min-width: 165px; margin-right: 10px
  }
}

.scroll {
  margin-top: 3%;
  max-height: 400px;
  min-height: 400px;
  overflow-y: auto;
}
</style>
