


















import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      value: '',
    };
  },
  methods: {
    clearSearch() {
      this.value = '';
    },
  },
  watch: {
    value(val) {
      this.$emit('search', val);
    },
  },
});
