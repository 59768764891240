import { render, staticRenderFns } from "./CreateUser.vue?vue&type=template&id=658f031f&scoped=true&"
import script from "./CreateUser.vue?vue&type=script&lang=ts&"
export * from "./CreateUser.vue?vue&type=script&lang=ts&"
import style0 from "./CreateUser.vue?vue&type=style&index=0&id=658f031f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "658f031f",
  null
  
)

export default component.exports