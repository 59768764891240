
























import Vue from 'vue';

export default Vue.extend({
  name: 'base-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String, // striped | hover
      default: '',
    },
    theadClasses: {
      type: String,
      default: '',
    },
    tbodyClasses: {
      type: String,
      default: '',
    },
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  methods: {
    itemValue(item: any, column: any) {
      if (typeof column.key !== 'undefined') {
        if (typeof column.replaceValue !== 'undefined') {
          return column.replaceValue(item[column.key]);
        }
        return item[column.key];
      }

      return item[column.toLowerCase()];
    },
  },
});

