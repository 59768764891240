























































import { Component, Vue } from 'vue-property-decorator';
import BaseTable from '@/components/BaseTable.vue';
import { UserDTO } from '@/data/dto/user.dto';
import Card from '@/components/Cards/Card.vue';
import EditUsersModal from '@/views/AuthorizationPanel/panels/Users/EditUsersModal.vue';

@Component({
  components: {
    BaseTable,
    Card,
  },
})
export default class UsersPanel extends Vue {
  isOnError = false;

  isLoading = false;

  search = ''

  applicationUsers: UserDTO[] = [];

  get currentUser() {
    return 'this.$store.state.user.current';
  }

  async beforeMount() {
    await this.reloadTable();
  }

  updateFilterQuery(search = '') {
    this.search = search;
  }

  usersFilteredBySearch() {
    return this.$searchUtils.searchAll<UserDTO>(this.search, this.applicationUsers,
      (user) => user.name || '');
  }

  reloadTable() {
    this.$store.dispatch('getAllUsers')
      .then((res: UserDTO[]) => {
        this.applicationUsers = res;
      })
      .catch(() => {
        this.isOnError = true;
      });
  }

  notifyError() {
    this.$notification.notify({
      message: 'Não foi possivel executar a operação. Por favor tente novamente.',
      icon: 'tim-icons icon-bell-55',
      horizontalAlign: 'center',
      verticalAlign: 'top',
      type: 'danger',
      timeout: 3000,
    });
  }

  notifySuccess(message: string) {
    this.$notification.notify({
      message,
      icon: 'tim-icons icon-bell-55',
      horizontalAlign: 'center',
      verticalAlign: 'top',
      type: 'success',
      timeout: 2000,
    });
  }

  async removeUser(email: string) {
    return new Promise((resolve, reject) => this.$store.dispatch('deleteUser', { email })
      .then(() => resolve()).catch(() => reject()));
  }

  openRemoveUserModal(email: string) {
    this.$modal.show('dialog', {
      title: `Você deseja deletar o usuário ${email}`,
      buttons: [
        {
          title: 'Cancelar',
          handler: () => {
            this.$modal.hide('dialog');
          },
        },
        {
          title: 'Sim',
          handler: async () => {
            await this.removeUser(email)
              .then(() => {
                this.$modal.hide('dialog');
                this.reloadTable();
                this.notifySuccess('Usuário removido com sucesso');
              })
              .catch(() => this.notifyError());
          },
        },
      ],
    });
  }

  openHandleUserStatusModal(email: string, currentStatus: boolean) {
    const getStatusName = (status: boolean) => (status ? 'Ativo' : 'Desativado');
    this.$modal.show(
      'dialog',
      {
        title: `Você deseja alterar o status do usuário ${email}`,
        text: `${getStatusName(currentStatus)} para ${getStatusName(!currentStatus)}`,
        buttons: [
          {
            title: 'Cancelar',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Sim',
            handler: async () => {
              await this.toggleUserStatus(email, !currentStatus)
                .then(() => {
                  this.$modal.hide('dialog');
                  this.reloadTable();
                  this.notifySuccess('Usuário alterado com sucesso');
                })
                .catch(() => this.notifyError());
            },
          },
        ],
      },
    );
  }

  async toggleUserStatus(email: string, active: boolean) {
    return new Promise((resolve, reject) => this.$store.dispatch('updateUserStatus',
      { email, active })
      .then(() => resolve())
      .catch(() => reject));
  }

  openEditModal(email: string, name: string) {
    this.$modal.show(
      EditUsersModal,
      { userEmail: email, userName: name },
      { adaptive: true, height: 'auto' },
      {
        'before-close': this.reloadTable,
      },
    );
  }

  itemValue(item: any, column: any) {
    if (typeof column.replaceValue !== 'undefined') {
      return column.replaceValue(item[column.key]);
    }
    return item[column.key];
  }

  get table() {
    return {
      columns: [{
        key: 'email',
        label: 'Email',
      },
      {
        key: 'name',
        label: 'Nome',
      },
      {
        key: 'created_at',
        label: 'Data de Inclusão',
      },
      {
        key: 'updated_at',
        label: 'Data de Alteração',
      },
      {
        key: 'total',
        label: 'Regras',
      },
      {
        key: 'active',
        label: 'Status',
        replaceValue: (status: boolean) => {
          if (status) {
            return '<div class="text-success">Ativo</div>';
          }

          return '<div class="text-danger">Desativado</div>';
        },
      },
      ],
    };
  }
}
