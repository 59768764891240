














import Vue from 'vue';

export default Vue.extend({
  props: {
    label: {
      type: String,
    },

    icon: {
      type: String,
    },

    placeHolder: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  mounted() {
    this.value = this.placeHolder;
  },
  data() {
    return {
      value: '',
    };
  },

  watch: {
    value(val) {
      this.$emit('input', val);
    },
  },
});
