























































import { Component, Vue } from 'vue-property-decorator';
import { GroupDTO } from '@/data/dto/group.dto';
import Card from '@/components/Cards/Card.vue';
import EditGroupModal from '@/views/AuthorizationPanel/panels/Groups/EditGroupModal.vue';

@Component({
  components: {
    Card,
  },
})
export default class PermissionsPanel extends Vue {
  isOnError = false;

  isLoading = true;

  groups: GroupDTO[] = [];

  search = ''

  async beforeCreate() {
    await this.$store.dispatch('getAllGroups').then((res) => {
      this.isLoading = false;
      this.groups = res;
    }).catch(() => {
      this.isOnError = true;
    });
  }

  updateFilterQuery(search = '') {
    this.search = search;
  }

  notifyError() {
    this.$notification.notify({
      message: 'Não foi possivel executar a operação. Por favor tente novamente.',
      icon: 'tim-icons icon-bell-55',
      horizontalAlign: 'center',
      verticalAlign: 'top',
      type: 'danger',
      timeout: 3000,
    });
  }

  notifySuccess(message: string) {
    this.$notification.notify({
      message,
      icon: 'tim-icons icon-bell-55',
      horizontalAlign: 'center',
      verticalAlign: 'top',
      type: 'success',
      timeout: 2000,
    });
  }

  groupsFilteredBySearch() {
    return this.$searchUtils.searchAll<GroupDTO>(this.search, this.groups,
      (group) => group.name);
  }

  reloadTable() {
    this.$store.dispatch('getAllGroups').then((res) => {
      this.isLoading = false;
      this.groups = res;
    }).catch(() => {
      this.isOnError = true;
    });
  }

  toggleGroupStatus(uuid: string, active: boolean) {
    return new Promise((resolve, reject) => this.$store.dispatch('updateGroupStatus', { uuid, active })
      .then(() => {
        resolve();
      }).catch(() => {
        reject();
      }));
  }

  removeGroup(uuid: string) {
    return new Promise((resolve, reject) => this.$store.dispatch('removeGroup', { uuid })
      .then(() => {
        resolve();
      }).catch(() => {
        reject();
      }));
  }

  // eslint-disable-next-line class-methods-use-this
  itemValue(item: any, column: any) {
    if (typeof column.replaceValue !== 'undefined') {
      return column.replaceValue(item[column.key]);
    }
    return item[column.key];
  }

  openRemoveGroupModal(uuid: string, name: string) {
    this.$modal.show('dialog', {
      title: `Você deseja deletar o grupo ${name}`,
      buttons: [
        {
          title: 'Cancelar',
          handler: () => {
            this.$modal.hide('dialog');
          },
        },
        {
          title: 'Sim',
          handler: async () => {
            await this.removeGroup(uuid)
              .then(() => {
                this.$modal.hide('dialog');
                this.reloadTable();
                this.notifySuccess('Grupo excluido com sucesso');
              })
              .catch(() => this.notifyError());
          },
        },
      ],
    });
  }

  openHandleGroupStatusModal(uuid: string, groupName: string, currentStatus: boolean) {
    const getStatusName = (status: boolean) => (status ? 'Ativo' : 'Desativado');

    this.$modal.show('dialog', {
      title: `Você deseja alterar o status do grupo ${groupName}`,
      text: `${getStatusName(currentStatus)} para ${getStatusName(!currentStatus)}`,
      buttons: [
        {
          title: 'Cancelar',
          handler: () => {
            this.$modal.hide('dialog');
          },
        },
        {
          title: 'Sim',
          handler: async () => {
            await this.toggleGroupStatus(uuid, !currentStatus)
              .then(() => {
                this.$modal.hide('dialog');
                this.reloadTable();
                this.notifySuccess('Grupo alterado com sucesso');
              })
              .catch(() => this.notifyError());
          },
        },
      ],
    });
  }

  // eslint-disable-next-line class-methods-use-this
  openEditGroupModal(uuid: string, name: string) {
    this.$modal.show(
      EditGroupModal,
      { uuid, groupName: name },
      { adaptive: true, height: 'auto' },
      {
        'before-close': this.reloadTable,
      },
    );
  }

  // eslint-disable-next-line class-methods-use-this
  get table() {
    return {
      columns: [{
        key: 'name',
        label: 'Regra',
      },
      {
        key: 'active',
        label: 'Status',
        replaceValue: (status: boolean) => {
          if (status) {
            return '<div class="text-success">Ativo</div>';
          }

          return '<div class="text-danger">Desativado</div>';
        },
      },
      ],
    };
  }
}
